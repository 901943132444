<template>
  <v-row>
    <v-col cols="6">
      <ad-channels />
    </v-col>
    <v-col cols="6">
      <ad-posts />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AdCabinet',
  components:{
    AdChannels: () => import('@/views/ad/AdChannels'),
    AdPosts: () => import('@/views/ad/AdPosts')
  }
}
</script>

<style scoped>

</style>
